(function () {
    $.MsgBox = {
        Alert: function (msg, callback, type) {
            buildAlert(msg, callback, type);
            btnAlertOK(callback);
        },
        Confirm: function (msg, callback, title, paras) {
            buildConfirm(msg, title, "1");
            btnOk(callback, paras);
            btnNo();
        },
        DelConfirm: function (msg, callback, title, paras) {
            buildConfirm(msg, title, "2");
            btnOk(callback, paras);
            btnNo();
        },
        NoticeConfirm: function (msg, title) {
            buildConfirm(msg, title, "3");
            btnNo();
        },
        ParasConfirm: function (msg, callback, title, paras) {
            buildConfirm(msg, title, "4");
            btnOk(callback, paras);
            btnNo();
        },
        Pop: function (url, title, pSize, callback, paras) {
            $.MsgBox.espCallBack = callback;
            buildFrame(url, title, pSize, callback, paras);
        },
        AlertDelay: function (msg, callback, type, paras) {
            buildAlert(msg, null, type);
            btnAlertOK(callback, paras);
        }
    }

    $.MsgBox.espCallBack = null;

    var buildAlert = function (msg, callback, type) {
        var topWind = $x.top();
        var dialogHTML = "";
        topWind.$("#popupMsg").remove();
        dialogHTML = '<div  style="display:none;z-index:5001;border-radius: 5px;overflow: hidden;" class="ui-popup-container ui-popup-active" id="popupMsg">';
        dialogHTML += '<div class="ui-content ui-popup ui-body-inherit ui-corner-all"  style="background-color:#fff;">';
        if (type == "alert") {
            dialogHTML += '<p class="alert" style="padding:2em;margin-left:1.6em;" id="tipcontent"></p>';
        } else if (type == "message") {
            dialogHTML += '<p class="message" style="padding:2em;margin-left:1.6em;" id="tipcontent"></p>';
        } else if (type == "warnning") {
            dialogHTML += '<p class="warnning" style="padding:2em;margin-left:1.5em;margin-bottom:0;" id="tipcontent"></p>';
            dialogHTML += '<a  data-inline="true" data-role="button" href="javascript:" id="appAlertOK" class="ui-confirm5" role="button">' + kygMsg(4000001) + '</a>';
        }
        else if (type == "formTip") {
            var h = $(window).height() * 0.6 + 'px;';
            var w = $(window).width() * 0.6 + 'px;';
            dialogHTML += '<div class="formTip" style="padding:2em;margin-bottom:0;overflow:scroll;height:' + h + ' width:' + w + '" id="tipcontent"></div>';
            dialogHTML += '<a data-inline="true" data-role="button" href="javascript:" id="appAlertOK" class="ui-confirm5" role="button">' + kygMsg(4000001) + '</a>';
        }
        dialogHTML += '</div>';
        dialogHTML += '</div>';
        dialogHTML += getPopWinMaskHTML();
        topWind.$(topWind.document.body).append(dialogHTML);
        topWind.$("#tipcontent").html(msg);
        topWind.$('.window-mask').height($(window.document).height());
        locateFixedCenter('popupMsg');
        topWind.$("#popupMsg,#popwinmask").show();

        if (type != "warnning" && type != "formTip") {
            window.setTimeout(function () { topWind.$("#popupMsg,#popwinmask").hide(); if (callback) { callback(); } }, 1500);
        }
    }

    var buildConfirm = function (msg, title, dialogType) {
        var topWind = $x.top();
        topWind.$("#pop_confirm_dialog").remove();
        var dialogHTML = '<div class="ui-popup-container pop in ui-popup-active" id="pop_confirm_dialog"><div data-role="popup" class="ui-popup ui-body-inherit ui-corner-all">';

        if (dialogType == 3 || dialogType == 4) {
            //NoticeConfirm,ParasConfirm            
            dialogHTML += '<div data-role="header" role="banner" class="ui-header ui-bar-a"><h1 id="popupComfirmTitle" class="ui-title" role="heading" aria-level="1">' + title + '</h1></div>';
        }
        dialogHTML += '<div class="ui-content" role="main">';
        if (dialogType == 1 || dialogType == 2) {
            dialogHTML += '<div id="core-content" style="text-align: center;"></div>';
        } else {
            dialogHTML += '<div id="core-content"></div>';
        }
        dialogHTML += '</div>';
        var suffix = ' category' + dialogType;
        if (dialogType == 1) {
            //Confirm
            dialogHTML += '<div class="pop-form-toolbar' + suffix + '">';
            dialogHTML += '<span data-inline="true" data-role="button" id="appconfirmno" class="ui-return4" role="button">' + kygMsg(4000080) + '</span>';
            dialogHTML += '<span data-inline="true" data-role="button" id="appconfirmok" class="ui-confirm4" role="button">' + kygMsg(4000001) + '</span>';
            dialogHTML += '</div>';

        } else if (dialogType == 2) {
            //Delconfirm
            dialogHTML += '<div class="pop-form-toolbar' + suffix + '">';
            dialogHTML += '<span data-inline="true" data-role="button" id="appconfirmno" class="ui-return2" role="button">' + kygMsg(4000080) + '</span>';
            dialogHTML += '<span data-inline="true" data-role="button" id="appconfirmok" class="ui-confirm2" role="button">' + kygMsg(4000077) + '</span>';
            dialogHTML += '</div>';

        } else if (dialogType == 3) {
            //NoticeConfirm
            dialogHTML += '<div class="pop-form-toolbar' + suffix + '">';
            dialogHTML += '<span data-inline="true" data-role="button" id="appconfirmno" class="ui-confirm3" role="button">' + kygMsg(4000001) + '</span>';
            dialogHTML += ' </div>';

        } else if (dialogType == 4) {
            //ParasConfirm
            dialogHTML += '<div class="pop-form-toolbar' + suffix + '">';
            dialogHTML += '<span data-inline="true" data-role="button" id="appconfirmno" class="ui-return" role="button">' + kygMsg(4000080) + '</span>';
            dialogHTML += '<span data-inline="true" data-role="button" id="appconfirmok" class="ui-confirm" role="button">' + kygMsg(4000001) + '</span>';
            dialogHTML += '</div>';
        }
        dialogHTML += ' </div>';
        dialogHTML += ' </div>';
        dialogHTML += getPopWinMaskHTML();
        topWind.$(topWind.document.body).append(dialogHTML);
        dynamicComfirm(msg, title, dialogType);
    }

    var buildFrame = function (url, title, pSize, callback, paras) {
        var pH;
        if (pSize && pSize[1]) pH = pSize[1];
        var twin = $x.top();
        var dialogHTML = "";
        twin.$("#PopupIframeDialog").remove();
        dialogHTML = '<div style="display:none;z-index: 5001;border-radius: 5px;overflow: hidden;" class="ui-popup-container pop in ui-popup-active" id="PopupIframeDialog"><div  data-role="popup" class="ui-popup ui-body-inherit ui-corner-all">';
        dialogHTML += '<div style="z-index: 1002;background-color:#fff;" class="ui-content" role="main">';
        dialogHTML += '<div id="IframeContent" style="overflow:auto;-webkit-overflow-scrolling:touch;width:100%;height:100%;">';
        dialogHTML += '<iframe src="about:blank" style="width: 100%;" id="PopKFrame" name="PopKFrame" allowtransparency="true" frameborder="0" scrolling="no"></iframe></div>';
        dialogHTML += '</div></div></div>';
        dialogHTML += getPopWinMaskHTML();
        twin.$(twin.document.body).append(dialogHTML);
        if (pH) { twin.$("#PopKFrame").height(pH); }
        else { twin.$("#PopKFrame").height(twin.$(window).height() - 25); }
        twin.$("#PopKFrame").attr("src", url);
        twin.$('.window-mask').height($(window.document).height());
        twin.$("#IframeContent").trigger('create');
        twin.$(".ui-shadow").each(function () { $(this).attr("class", $(this).attr("class").replace("ui-shadow", "")); })
        var container = twin.$("#IframeContent").parent();
        $(container).width($(twin).width() - 20);
        setTimeout(function () { locateFixedCenter('PopupIframeDialog'); twin.$("#PopupIframeDialog,#popwinmask").show(); }, 500);
    }

    var btnOk = function (callback, paras) {
        $x.top().$("#appconfirmok").unbind("click");
        $x.top().$("#appconfirmok").click(function () {
            if (typeof (callback) == 'function') {
                window.setTimeout(function () { callback(paras); }, 200);
            }
        });
    }

    var btnNo = function () {
        $x.top().$("#appconfirmno").click(function () {

            $x.top().$('.sp-disable').each(function () {
                $(this).attr("class", 'sp-confirm');
            });

            $x.top().$('.ui-disable').each(function () {
                $(this).attr("class", 'ui-confirm');
            });

            $x.top().$("#pop_confirm_dialog,#popwinmask,#KCalendar").remove();
        });
    }

    var btnAlertOK = function (callback, paras) {

        $x.top().$("#appAlertOK").click(function () {

            var popupDialog = $x.top().$(".ui-popup");
            if ($(popupDialog).length == 0) {
                $x.top().$("#popwinmask").hide();
            } else {

                var popKFrame = $x.top().$("#PopKFrame");

                if ($(popKFrame).length == 0) {

                    if ($(popupDialog).find("input").length == 0) {

                        $x.top().$("#popwinmask").hide();
                    }
                }
            }

            $x.top().$("#popupMsg").hide();

            if (typeof (callback) == 'function') {
                window.setTimeout(function () { callback(paras); }, 200);
            }
        });
    }

    var getPopWinMaskHTML = function () {
        var maskHtml = '';
        if ($x.top().$("#popwinmask").length == 0) {
            maskHtml = '<div id="popwinmask" class="window-mask" style="display: none; z-index: 3001;"></div>';
        }
        return maskHtml;
    }

    var locateFixedCenter = function (id) {

        var target = $x.top().$("#" + id);
        if (!target) return false;
        var popDialogPosition = 'fixed';
        var ua = navigator.userAgent.toLowerCase();
        if (/android/.test(ua)) {
            var tr = $x.top().$("#core-content tr");
            if ($(tr).length >= 3) {
                popDialogPosition = 'absolute';
            }
        }
        $(target).css({
            position: popDialogPosition,
            left: ($(window).width() - $(target).outerWidth()) / 2,
            top: ($(window).height() - $(target).outerHeight()) / 2
        });
    }

    var dynamicComfirm = function (msg, title, type) {
        var twin = $x.top();
        var confirm = twin.$("#core-content");
        twin.$(confirm).html(msg);

        var pwdSecond = twin.$(confirm).find(":password");
        if ($(pwdSecond).length == 1) {
            $(pwdSecond).removeAttr("onchange");
        }

        if (type == 3 || type == 4) {

            if (title) {
                twin.$("#popupComfirmTitle").html(title);
            }
            else {
                twin.$("#popupComfirmTitle").parent().remove();
            }
        }
        twin.$(".dlg-buttons").remove();
        twin.$(confirm).find("div").removeAttr("style");
        twin.$(confirm).find("table").removeAttr("style");
        twin.$(confirm).find("td").removeAttr("style");
        twin.$('.window-mask').height($(window.document).height());
        twin.$("#core-content").trigger('create');
        twin.$(".ui-shadow").each(function () { $(this).attr("class", $(this).attr("class").replace("ui-shadow", "")); })
        if (type == 3 || type == 4) twin.$("#core-content").parent().css({ "padding": "10px" });
        locateFixedCenter('pop_confirm_dialog');
        twin.$("#pop_confirm_dialog,#popwinmask").show();
    }

})(); 